import weaviate from 'weaviate-ts-client';
import * as tf from '@tensorflow/tfjs';
import { load } from '@tensorflow-models/universal-sentence-encoder';

let encoder = null;

// Initialize Weaviate client
const client = weaviate.client({
  scheme: 'https',
  host: window.location.host + '/api',
  apiKey: new weaviate.ApiKey(import.meta.env.VITE_WEAVIATE_API_KEY)
});

// Add console logging for debugging
console.log('Initializing Weaviate client with:', {
  host: window.location.host + '/api',
  apiKeyAvailable: !!import.meta.env.VITE_WEAVIATE_API_KEY
});

// Initialize schema and client
const initializeSchema = async () => {
  try {
    console.log('Initializing schema...');
    
    // Delete existing schema if it exists
    try {
      await client.schema.classDeleter().withClassName('PersonalInfo').do();
      await client.schema.classDeleter().withClassName('WeaviateFeature').do();
      await client.schema.classDeleter().withClassName('CompetitiveInfo').do();
      console.log('Deleted existing schema');
    } catch (error) {
      console.log('No existing schema to delete');
    }

    // Create schema for PersonalInfo
    await client.schema.classCreator()
      .withClass({
        class: 'PersonalInfo',
        vectorIndexConfig: {
          distance: "cosine"
        },
        properties: [
          {
            name: 'content',
            dataType: ['text'],
            description: 'The content of the personal information'
          },
          {
            name: 'category',
            dataType: ['text'],
            description: 'The category of personal information'
          }
        ]
      })
      .do();

    // Create schema for WeaviateFeature
    await client.schema.classCreator()
      .withClass({
        class: 'WeaviateFeature',
        vectorIndexConfig: {
          distance: "cosine"
        },
        properties: [
          {
            name: 'feature',
            dataType: ['text'],
            description: 'Description of the Weaviate feature'
          },
          {
            name: 'category',
            dataType: ['text'],
            description: 'Category of the feature'
          },
          {
            name: 'benefit',
            dataType: ['text'],
            description: 'Benefit of the feature'
          }
        ]
      })
      .do();

    // Create schema for CompetitiveInfo
    await client.schema.classCreator()
      .withClass({
        class: 'CompetitiveInfo',
        vectorIndexConfig: {
          distance: "cosine"
        },
        properties: [
          {
            name: 'competitor',
            dataType: ['text'],
            description: 'Name of the competitor'
          },
          {
            name: 'comparison',
            dataType: ['text'],
            description: 'Comparison with Weaviate'
          },
          {
            name: 'category',
            dataType: ['text'],
            description: 'Category of the comparison'
          }
        ]
      })
      .do();

    console.log('Schema created successfully');
    return true;
  } catch (error) {
    console.error('Error initializing schema:', error);
    throw error;
  }
};

// Initialize the Universal Sentence Encoder
const initializeEncoder = async () => {
  if (!encoder) {
    console.log('Loading Universal Sentence Encoder...');
    encoder = await load();
    console.log('Universal Sentence Encoder loaded successfully');
  }
  return encoder;
};

// Get vector embedding using TensorFlow.js
const getEmbedding = async (text) => {
  try {
    console.log('Getting embedding for text:', text);
    
    // Make sure encoder is initialized
    const model = await initializeEncoder();
    
    // Get embeddings
    const embeddings = await model.embed([text]);
    
    // Convert to array
    const embedding = Array.from(await embeddings.data());
    
    console.log('Successfully generated embedding of length:', embedding.length);
    return embedding;
    
  } catch (error) {
    console.error('Error getting embedding:', error);
    throw error;
  }
};

// Add sample data
const addSampleData = async () => {
  try {
    console.log('Adding sample data...');
    
    // Personal info data
    const personalData = [
      {
        content: "Experienced B2B SaaS sales professional with proven track record in pipeline generation and closing new business",
        category: "background"
      },
      {
        content: "Drove Invary from pre-revenue to $876k ARR, plus $700k in service revenue in under a year",
        category: "achievement"
      },
      {
        content: "Secured largest API Gateway deal at Cloudflare in FY23, contributing to $2.4M+ revenue",
        category: "achievement"
      },
      {
        content: "Consistently exceeded quota at Cloudflare (187%-205%), earning top sales awards",
        category: "achievement"
      },
      {
        content: "Deep experience selling to enterprise, cloud-native, and government customers in competitive markets",
        category: "expertise"
      },
      {
        content: "Expert at navigating complex sales cycles and fostering long-term enterprise partnerships",
        category: "expertise"
      }
    ];

    // Add personal info with vectors
    for (const data of personalData) {
      const vector = await getEmbedding(data.content);
      await client.data
        .creator()
        .withClassName('PersonalInfo')
        .withProperties(data)
        .withVector(vector)
        .do();
    }

    // Weaviate features data
    const featureData = [
      {
        feature: "AI-Native Architecture purpose-built for vector storage and search",
        benefit: "Enables efficient management of unstructured data for generative AI applications",
        category: "architecture"
      },
      {
        feature: "Enterprise-Grade Scalability with real-time search capabilities",
        benefit: "Ideal for hyperscale enterprise applications and large datasets",
        category: "scalability"
      },
      {
        feature: "Plug-and-Play Modules with pre-integrated connectors",
        benefit: "Simplifies deployment and accelerates time-to-value",
        category: "integration"
      },
      {
        feature: "Hybrid Search combining traditional keyword with semantic vector search",
        benefit: "Bridges gap between legacy systems and next-generation AI tools",
        category: "search"
      },
      {
        feature: "Enterprise Security with RBAC and authentication protocols",
        benefit: "Ensures compliance with strict corporate and regulatory standards",
        category: "security"
      }
    ];

    // Add features with vectors
    for (const data of featureData) {
      const vector = await getEmbedding(data.feature);
      await client.data
        .creator()
        .withClassName('WeaviateFeature')
        .withProperties(data)
        .withVector(vector)
        .do();
    }

    // Competitive info data
    const competitiveData = [
      {
        competitor: "Hyperscalers (AWS, Google, Azure)",
        comparison: "Lack AI-native specialization, prioritizing general-purpose solutions over specialized vector capabilities",
        category: "cloud"
      },
      {
        competitor: "Legacy Databases",
        comparison: "Fail to support vectorized data natively, requiring inefficient workarounds for AI workloads",
        category: "legacy"
      },
      {
        competitor: "Vector DB Competitors",
        comparison: "Weaviate offers more comprehensive solution with hybrid search, modularity, and enterprise features",
        category: "direct"
      },
      {
        competitor: "Elasticsearch",
        comparison: "Retrofitting vector capabilities onto text-search architecture, lacking seamless AI integration",
        category: "search"
      }
    ];

    // Add competitive info with vectors
    for (const data of competitiveData) {
      const vector = await getEmbedding(data.comparison);
      await client.data
        .creator()
        .withClassName('CompetitiveInfo')
        .withProperties(data)
        .withVector(vector)
        .do();
    }

    console.log('Sample data added successfully');
  } catch (error) {
    console.error('Error adding sample data:', error);
    throw error;
  }
};

// Initialize everything
const initialize = async () => {
  try {
    await initializeSchema();
    await addSampleData();
    console.log('Initialization complete');
  } catch (error) {
    console.error('Error during initialization:', error);
  }
};

// Call initialize when the module loads
initialize();

export const searchAcrossClasses = async (query) => {
  try {
    const vector = await getEmbedding(query);
    
    const results = await Promise.all([
      // Search PersonalInfo
      client.graphql
        .get()
        .withClassName('PersonalInfo')
        .withFields(['content', 'category'])
        .withNearVector({
          vector: vector
        })
        .withLimit(3)
        .do(),

      // Search WeaviateFeature
      client.graphql
        .get()
        .withClassName('WeaviateFeature')
        .withFields(['feature', 'benefit', 'category'])
        .withNearVector({
          vector: vector
        })
        .withLimit(3)
        .do(),

      // Search CompetitiveInfo
      client.graphql
        .get()
        .withClassName('CompetitiveInfo')
        .withFields(['competitor', 'comparison', 'category'])
        .withNearVector({
          vector: vector
        })
        .withLimit(3)
        .do(),
    ]);

    // Process and combine results
    const combinedResults = [
      ...results[0].data.Get.PersonalInfo.map(item => ({
        type: 'PersonalInfo',
        content: item.content,
        category: item.category
      })),
      ...results[1].data.Get.WeaviateFeature.map(item => ({
        type: 'WeaviateFeature',
        content: item.feature,
        benefit: item.benefit,
        category: item.category
      })),
      ...results[2].data.Get.CompetitiveInfo.map(item => ({
        type: 'CompetitiveInfo',
        competitor: item.competitor,
        content: item.comparison,
        category: item.category
      }))
    ];

    return combinedResults;
  } catch (error) {
    console.error('Error searching across classes:', error);
    throw error;
  }
};

export default {
  client,
  searchAcrossClasses,
  initialize
};
