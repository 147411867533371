import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const WhyWeaviate = () => {
  const [activeTab, setActiveTab] = useState('features');

  const features = [
    {
      title: "AI-Native Architecture",
      description: "Purpose-built for vector storage and search, enabling efficient management of unstructured data like text, images, and videos",
      benefits: "Critical for powering modern generative AI applications"
    },
    {
      title: "Enterprise-Grade Scalability",
      description: "Seamlessly handles vast datasets with real-time search and retrieval capabilities",
      benefits: "Ideal for hyperscale enterprise applications"
    },
    {
      title: "Plug-and-Play Modules",
      description: "Pre-integrated connectors for common data sources and embeddings (OpenAI, Hugging Face)",
      benefits: "Simplifies deployment and accelerates time-to-value"
    },
    {
      title: "Hybrid Search",
      description: "Combines traditional keyword search with semantic vector search",
      benefits: "Bridges the gap between legacy systems and next-generation AI tools"
    },
    {
      title: "Enterprise Security",
      description: "Role-based access control (RBAC) and enterprise authentication protocols",
      benefits: "Ensures compliance with strict corporate and regulatory standards"
    },
    {
      title: "Open Source Excellence",
      description: "Strong commitment to open source with active community engagement and transparent development",
      benefits: "No vendor lock-in, community-driven innovation, and enterprise-grade support"
    }
  ];

  const competitors = [
    {
      name: "Hyperscalers (AWS, Google, Azure)",
      comparison: "While they provide vector database options as add-ons, they lack the AI-native specialization Weaviate offers, often prioritizing general-purpose solutions"
    },
    {
      name: "Legacy Databases",
      comparison: "Traditional databases fail to support vectorized data natively, requiring inefficient workarounds or bolt-on solutions, making them ill-suited for AI workloads"
    },
    {
      name: "Vector DB Competitors",
      comparison: "Weaviate stands out with its focus on hybrid search, modularity, and enterprise-grade features, offering a more comprehensive and scalable solution"
    },
    {
      name: "Incumbents Pivoting to AI",
      comparison: "Databases like Elasticsearch are retrofitting vector search capabilities, but their architectures lack the seamless integration Weaviate provides for AI-native use cases"
    }
  ];

  const marketTrends = [
    {
      trend: "Continuous Evolution of Databases",
      description: "Industry evolution from relational to NoSQL to AI-native databases. Weaviate leads this evolution, redefining enterprise data management."
    },
    {
      trend: "Enterprise Digital Transformation",
      description: "Growing reliance on AI applications for competitive advantage, requiring purpose-built databases like Weaviate."
    },
    {
      trend: "Unstructured Data Explosion",
      description: "Proliferation of unstructured data driving demand for vector databases that enable fast, accurate, and scalable AI-powered retrieval."
    },
    {
      trend: "Convergence of Search and AI",
      description: "Search evolving from utility to strategic capability for personalized experiences and intelligent automation."
    },
    {
      trend: "Data Democratization",
      description: "Enterprises seeking to make AI capabilities accessible across teams, positioning Weaviate as the go-to platform."
    }
  ];

  const tabVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto py-8"
    >
      <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">
        Why Weaviate is the Future
      </h1>
      
      <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
        In today's digital-first economy, enterprises that fail to adopt AI risk being left behind. 
        Weaviate provides the best foundation for AI applications, positioning businesses at the 
        cutting edge of innovation.
      </p>

      <div className="flex justify-center mb-8">
        <div className="inline-flex rounded-lg bg-gray-100 p-1">
          {['features', 'competition', 'market'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-6 py-2 rounded-lg font-medium transition-colors ${
                activeTab === tab
                  ? 'bg-white shadow-sm text-blue-600'
                  : 'text-gray-600 hover:text-gray-800'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={tabVariants}
          transition={{ duration: 0.2 }}
        >
          {activeTab === 'features' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {features.map((feature) => (
                <motion.div
                  key={feature.title}
                  className="bg-white rounded-lg shadow-lg p-6"
                  whileHover={{ scale: 1.02 }}
                >
                  <h3 className="text-xl font-semibold mb-3 text-gray-800">{feature.title}</h3>
                  <p className="text-gray-600 mb-4">{feature.description}</p>
                  <p className="text-sm text-blue-600">{feature.benefits}</p>
                </motion.div>
              ))}
            </div>
          )}

          {activeTab === 'competition' && (
            <div className="space-y-6">
              {competitors.map((competitor) => (
                <motion.div
                  key={competitor.name}
                  className="bg-white rounded-lg shadow-lg p-6"
                  whileHover={{ scale: 1.02 }}
                >
                  <h3 className="text-xl font-semibold mb-3 text-gray-800">{competitor.name}</h3>
                  <p className="text-gray-600">{competitor.comparison}</p>
                </motion.div>
              ))}
              
              <motion.div className="bg-blue-50 rounded-lg p-6 mt-8">
                <h3 className="text-lg font-semibold mb-3 text-blue-800">Weaviate's Commitment to Excellence</h3>
                <p className="text-blue-700">
                  As the #1 platform for enterprises to build AI applications, Weaviate combines the power of open source innovation 
                  with enterprise-grade support. Our dedicated team ensures customer success through comprehensive documentation, 
                  responsive support, and a thriving community of developers and users.
                </p>
              </motion.div>
            </div>
          )}

          {activeTab === 'market' && (
            <div className="space-y-6">
              {marketTrends.map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-6"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <h3 className="text-xl font-semibold mb-3 text-gray-800">{item.trend}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </motion.div>
              ))}
              
              <motion.div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg p-6 mt-8">
                <h3 className="text-lg font-semibold mb-3 text-blue-800">Database Evolution</h3>
                <ul className="space-y-2 text-blue-700">
                  <li>1. Relational Databases: Structured data in the pre-internet era</li>
                  <li>2. NoSQL Databases: Web-scale applications with semi-structured data</li>
                  <li>3. AI-Native Databases: Weaviate leading the next frontier for unstructured data and AI workloads</li>
                </ul>
              </motion.div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default WhyWeaviate;
