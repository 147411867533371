import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar';
import WhyMe from './components/WhyMe';
import WhyWeaviate from './components/WhyWeaviate';
import AIDemo from './components/AIDemo';
import { getModelStatus } from './services/modelWorker';
import { useEffect, useState } from 'react';

function App() {
  const [isModelReady, setIsModelReady] = useState(false);

  useEffect(() => {
    // Check model status every 2 seconds
    const interval = setInterval(() => {
      setIsModelReady(getModelStatus());
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <main className="container mx-auto px-4 py-8">
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<WhyMe />} />
              <Route path="/why-weaviate" element={<WhyWeaviate />} />
              <Route path="/ai-demo" element={<AIDemo />} />
            </Routes>
          </AnimatePresence>
          {!isModelReady && (
            <div className="model-status">
              Warming up AI model... Please wait a moment.
            </div>
          )}
        </main>
      </div>
    </Router>
  );
}

export default App;
