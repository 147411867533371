import { waitForModel } from './modelWorker';

// Using Hugging Face's Inference API with T5-small
const generateResponse = async (searchResults, userQuery) => {
  try {
    // Wait for model to be ready
    const isModelReady = await waitForModel();
    if (!isModelReady) {
      console.warn('Model not ready after maximum attempts');
      return formatSimpleResponse(searchResults);
    }

    // Format the context from search results
    const context = searchResults.map(result => {
      if (result.type === 'PersonalInfo') {
        return `Personal Info (${result.category}): ${result.content}`;
      } else if (result.type === 'WeaviateFeature') {
        return `Weaviate Feature (${result.category}): ${result.content}. Benefit: ${result.benefit}`;
      } else if (result.type === 'CompetitiveInfo') {
        return `Competitive Info (${result.competitor}): ${result.content}`;
      }
      return result.content;
    }).join('\n');

    // Construct the prompt
    const prompt = `summarize: Context: ${context}\n\nQuestion: ${userQuery}\n\nAnswer:`;

    // Call Hugging Face Inference API
    const headers = {
      "Authorization": `Bearer ${import.meta.env.VITE_HUGGINGFACE_TOKEN}`
    };

    console.log('Debug - Request body:', {
      inputs: prompt
    });

    const response = await fetch(
      'https://api-inference.huggingface.co/models/t5-small',
      {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          inputs: prompt
        })
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.log('Debug - Error response:', errorText);
      throw new Error(`Hugging Face API error: ${errorText}`);
    }

    const result = await response.json();
    if (Array.isArray(result) && result.length > 0) {
      return result[0][0]?.generated_text || 'I apologize, but I could not generate a response at this time.';
    }
    return result.generated_text || 'I apologize, but I could not generate a response at this time.';
  } catch (error) {
    console.error('Error generating response:', error);
    // Fallback to simple response if LLM fails
    return formatSimpleResponse(searchResults);
  }
};

// Fallback function for simple response formatting
const formatSimpleResponse = (searchResults) => {
  return searchResults.map(result => {
    if (result.type === 'PersonalInfo') {
      return `Personal Info (${result.category}): ${result.content}`;
    } else if (result.type === 'WeaviateFeature') {
      return `Weaviate Feature: ${result.content}`;
    } else if (result.type === 'CompetitiveInfo') {
      return `Competitive Info: ${result.content}`;
    }
    return result.content;
  }).join('\n\n');
};

export { generateResponse };
