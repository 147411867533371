import { useState } from 'react';
import { motion } from 'framer-motion';
import { searchAcrossClasses } from '../services/embeddingService';
import { generateResponse } from '../services/llmService';

const AIDemo = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [error, setError] = useState('');

  const suggestedPrompts = [
    "Should we hire Aaron Trott?",
    "How many burritos are we going to eat at SKO in San Diego?"
  ];

  const handleSearch = async (searchQuery = query) => {
    if (loading || !searchQuery.trim()) return;
    
    setLoading(true);
    setError('');
    try {
      console.log('Starting search with query:', searchQuery);
      
      // First, search across all classes using vector similarity
      const searchResults = await searchAcrossClasses(searchQuery);
      console.log('Search results:', searchResults);
      setResults(searchResults);

      if (searchResults.length > 0) {
        // Use our new LLM service to generate a response
        const response = await generateResponse(searchResults, searchQuery);
        setAiResponse(response);
      } else {
        setError('No results found. Please try a different question.');
        setAiResponse("I apologize, but I don't have enough relevant information to answer that question. Could you try rephrasing it or asking about a different topic?");
      }
    } catch (error) {
      console.error('Error in handleSearch:', error);
      setError(`Error: ${error.message}`);
      setAiResponse('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="max-w-4xl mx-auto p-6"
    >
      <h1 className="text-3xl font-bold mb-8">trottGPT</h1>
      
      <div className="mb-8">
        <div className="flex gap-4">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Ask anything about me or Weaviate..."
            className="flex-1 p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button
            onClick={() => handleSearch()}
            disabled={loading || !query.trim()}
            className={`px-6 py-3 rounded-lg bg-blue-600 text-white font-medium ${
              loading || !query.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
            }`}
          >
            {loading ? 'Processing...' : 'Ask'}
          </button>
        </div>
        
        <div className="mt-4">
          <p className="text-sm text-gray-600 mb-2">Try asking:</p>
          <div className="flex flex-wrap gap-2">
            {suggestedPrompts.map((prompt, index) => (
              <button
                key={index}
                onClick={() => {
                  setQuery(prompt);
                  handleSearch(prompt);
                }}
                disabled={loading}
                className={`px-4 py-2 rounded-lg bg-gray-100 text-gray-700 text-sm hover:bg-gray-200 transition-colors ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {prompt}
              </button>
            ))}
          </div>
        </div>
      </div>

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-red-50 text-red-700 p-4 rounded-lg mb-6"
        >
          {error}
        </motion.div>
      )}

      {aiResponse && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white p-6 rounded-lg shadow-lg mb-8"
        >
          <h2 className="text-xl font-semibold mb-4">Response:</h2>
          <p className="text-gray-700 whitespace-pre-wrap">{aiResponse}</p>
        </motion.div>
      )}

      {results.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-gray-50 p-6 rounded-lg"
        >
          <h2 className="text-xl font-semibold mb-4">Related Information:</h2>
          <div className="space-y-4">
            {results.map((result, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow">
                {result.content && (
                  <>
                    <p className="text-gray-700">{result.content}</p>
                    <span className="text-sm text-blue-600">{result.category}</span>
                  </>
                )}
                {result.feature && (
                  <>
                    <p className="text-gray-700">{result.feature}</p>
                    <p className="text-gray-600 mt-1">{result.benefit}</p>
                    <span className="text-sm text-blue-600">{result.category}</span>
                  </>
                )}
                {result.competitor && (
                  <>
                    <p className="font-medium text-gray-700">{result.competitor}</p>
                    <p className="text-gray-600 mt-1">{result.comparison}</p>
                    <span className="text-sm text-blue-600">{result.category}</span>
                  </>
                )}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default AIDemo;
