// Worker to keep the T5 model warm
const PING_INTERVAL = 60000; // Ping every minute
const MODEL_URL = 'https://api-inference.huggingface.co/models/t5-small';

let isModelReady = false;

const pingModel = async () => {
    try {
        const response = await fetch(MODEL_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${import.meta.env.VITE_HUGGINGFACE_TOKEN}`
            },
            body: JSON.stringify({
                inputs: "translate English to French: Hello"
            })
        });

        if (response.ok) {
            const result = await response.json();
            console.log('Model warm-up response:', result);
            isModelReady = true;
            console.log('Model is warm and ready');
        } else {
            const error = await response.text();
            console.warn('Model not ready:', error);
            isModelReady = false;
        }
    } catch (error) {
        console.error('Error pinging model:', error);
        isModelReady = false;
    }
};

// Initial ping
pingModel();

// Set up regular pinging
setInterval(pingModel, PING_INTERVAL);

export const waitForModel = async (maxAttempts = 5) => {
    let attempts = 0;
    while (!isModelReady && attempts < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        await pingModel();
        attempts++;
    }
    return isModelReady;
};

export const getModelStatus = () => isModelReady;
