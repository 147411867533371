import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const Navbar = () => {
  const location = useLocation();

  const links = [
    { path: '/', label: 'Why Me' },
    { path: '/why-weaviate', label: 'Why Weaviate' },
    { path: '/ai-demo', label: 'AI Demo' },
  ];

  return (
    <nav className="bg-white shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex space-x-8">
            {links.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className="relative inline-flex items-center px-1 pt-1 text-sm font-medium"
              >
                {location.pathname === link.path && (
                  <motion.div
                    layoutId="underline"
                    className="absolute left-0 right-0 bottom-0 h-0.5 bg-blue-500"
                  />
                )}
                <span className={location.pathname === link.path ? 'text-blue-600' : 'text-gray-500 hover:text-gray-700'}>
                  {link.label}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
