import { motion } from 'framer-motion';

const WhyMe = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="max-w-4xl mx-auto p-6"
    >
      <h1 className="text-3xl font-bold mb-8">Why I'm the Right Choice for Weaviate</h1>

      {/* Professional Background Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Professional Background</h2>
        <ul className="space-y-3 list-disc pl-6">
          <li>Experienced B2B SaaS sales rep with a knack for building pipeline and closing deals.</li>
          <li>Skilled at using consultative selling to manage objections and solve real customer problems.</li>
          <li>Naturally curious about people and what drives their decisions. I'm fascinated by the hero's journey and focus on making the buying process both enjoyable and easy.</li>
          <li>Confident selling cutting-edge, unbudgeted solutions by aligning them with customer business goals.</li>
        </ul>
      </section>

      {/* Key Achievements Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Key Achievements</h2>
        <ul className="space-y-3 list-disc pl-6">
          <li>Took Invary from $0 to $876k ARR, adding $700k in service revenue</li>
          <li>Closed Cloudflare's largest API Gateway deal in FY23, contributing to over $2.4M in revenue.</li>
          <li>Consistently crushed quota at Cloudflare, hitting 187%-205% and earning multiple top sales awards.</li>
          <li>Grew a single account from $300k to $2M+ ARR through strategic relationship building.</li>
          <li>Helped scale Cloudflare's Austin office from one hire (me!) to over 100, while mentoring the team.</li>
        </ul>
      </section>

      {/* Personal Fit Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Personally, Why I'm a Great Fit</h2>
        <div className="space-y-4">
          <p>
            I thrive in roles where I can bring structure to chaos, drive revenue, and collaborate with brilliant minds. 
            I've spent years mastering how to sell complex, technical solutions into highly competitive markets—this is my comfort zone.
          </p>
          <p>
            But beyond the numbers, I'm genuinely excited about Weaviate. AI-native databases aren't just a buzzword—they're 
            the backbone of what's next in enterprise tech. I'm all in on helping businesses unlock their data's potential with 
            Weaviate and leading the charge to make it <em>the</em> go-to platform for AI applications.
          </p>
          <p>
            I'm also a builder at heart—whether it's scaling sales orgs or landing a deal no one thought was possible, 
            I love being part of a company's growth story. With Weaviate, I see an incredible opportunity to not just sell 
            but to shape the future of how enterprises build with AI. Let's do it!
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default WhyMe;
